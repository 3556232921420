import jsPdf from 'jspdf';
import autoTable from 'jspdf-autotable';

const saveQuote = () => {

    const title='Personalized Quote'

    const doc = new jsPdf(); // Default is A4 paper, portrait, using millimeters for units

    doc.setFontSize(16);
    doc.text(title, 14, 10);

    let total = 500
    const tableData = [
        ['Service', 'Quantity', 'Unit Price', 'Total'],
        ['Service 1', 2, 10, 20],
        // ...
    ];
    
    //alert(title)

    // Créer un tableau
    doc.autoTable({
        head: tableData[0],
        body: tableData.slice(1)
    });

    doc.text(`Total : ${total} MAD`, 100, 200);
    doc.save('Quote.pdf');

}

export {saveQuote}