// App.js
import React from 'react';
import './Landing.css';
import Header from './Header';
import Articles from './Articles';
import CallToAction from '../shared/CallToAction';
import Footer from './Footer';
import Home from './Home';
import Slider from './Slider';
import Plans from '../Plan/Plans'
import Contact from './Contact';

function Landing() {
  const service=2
  return (
    <div className="App">
      <Header />
      <Slider />
      <Plans service={service} />
      <Articles service={service} />
      <Contact service={service} />
      <Home />
      <Footer />
    </div>
  );
}

export default Landing;

// <Home />
// <Slider />