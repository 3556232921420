import React from 'react'
import {Form} from 'react-bootstrap'

const InputControl = ({name,value,mode,type}) => {

    return (
        <div class="form-group form-row" >
            <label class="control-label col-sm-2" style={{width:"auto", textAlign:"right"}} for={"Input "+name}>{name}:</label>
            <div class="col-sm-10">
                <input type={type??"text"} class="form-control" id={name} 
                placeholder={name} 
                name={name} 
                defaultValue={value} 
                readOnly={(mode??0)===0} 
                onChange={(e) => {this.setState({value: value})}}
                />
            </div>
        </div>
        
    )
}

const TextControl = ({name,value,mode,type}) => {

    return (
        <Form.Group controlId={name} className='form-row'>
                <Form.Label className='col-sm-2'>{name} :</Form.Label>
                <Form.Control
                className='col-sm-10'
                type={type ?? 'text'}
                name={name}                
                placeholder={name}
                //value={value}
                defaultValue={value}                
                readOnly={mode===0}
                //  required
                />
            </Form.Group>        
    )
}

function  CheckControl(box = {name:"Checked", value:false, mode:0}) {
    const [checked, setChecked] = React.useState(box.value);
  
    return (
        <div class="form-group form-row">
      <label class="control-label col-sm-2" style={{width:"auto", textAlign:"right"}}>
          {box.name}: </label>
          <div class="col-sm-1">
        <input type="checkbox" name={box.name}
          defaultChecked={checked}
          onChange={() => setChecked(!checked)}
          readOnly={(box.mode??0)===0}
          class="form-control" 
        />
        
        </div>
      </div>
    )
}

export default InputControl
export {TextControl, CheckControl}