import React, {useState, useEffect} from 'react'
import './Plan.css'
import styled from 'styled-components'
import {Read} from '../shared/Api';
import {plan} from '../Config/en';
import { saveQuote } from './Quote';

const PlansContainer = styled.section`
    margin: 1rem auto;
    padding: 2rem 0;
    text-align: center;
    background-color: rgb(222, 222, 222);
`;

const Plans = ({service, Etat}) => {

    const [items , Items] = useState([])
    const [loaded, Load] = useState(false)

    useEffect(()=>{        
  
      if(!loaded) {
        Read(`/plan/${service}`, Items, Load)
        //console.log(items)
      }
  
  },[service])
  
    return (
loaded ? 
<PlansContainer  id="plans">
    <h3>{plan.title}</h3>

        <div className='Plans' >

{items.map((item) => (
    <Plan 
        name={item.Name} 
        info={item.Description} 
        price={item.Price}
        icon={item.Icon ?? plan.icon} 
        label={plan.order}
        to={plan.link}
    />
))}

</div>
</PlansContainer>
:
null

    )

}

const Plan = ({name, icon, info, price, label, to, style}) => {

    const [hover, Hover] = useState(false)
    const bc = name==='Free' ? 'rgb(217,234,211)': name==='Personal' ? 'rgb(208,224,227)' : 'rgb(217,210,233)'
    const image = icon.startsWith("https") ? icon : "images/"+icon

    const onHover = (e, h=true) => {
        e.target.style.background = h ? bc : 'transparent'
    }
    
    const onQuote = (e) => {
        saveQuote()
    }

    useEffect(()=>{        

    },[hover])

    return (
        <div className='Plan' style={style}  
            onMouseEnter={(e) => onHover(e)}
            onMouseLeave={(e) => onHover(e,false)}>

            <div className="center">
                <a target="_blank" rel="noopener noreferrer" href={to}>
                    <img src={image} alt={name+": "+info} style={{ backgroundColor: bc }} />
                </a>
            </div>
            
            <div className="PlanName">
                <h2 style={{fontWeight: 'bold'}}>{name}</h2>
                <p><b>{price}</b> {'DH HT/mois'}</p>
                <h6 className="PlanInfo">{info}</h6>
                <br/>
                <Button onClick={(e) => onQuote(e)}>{label}</Button>
                <br/>
            </div>
        </div>
    )
}

const Button = styled.button`
    border: 1px solid #616A94;
    border-radius: 50px;
    padding: 15px 30px;
    margin: 10px;
    text-decoration: none ;
    text-align: center;
    color: white;
    background-color: darkblue;
    transition: 0.3s;
    font-size: 1em;
    cursor: pointer;
    outline: none;
    
    &:hover {
        color: white;
        font-weight: bold;
        background-color: blue;
        border : 6px solid silver ;
    }
`;

export default Plans
