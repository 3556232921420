
const Config = {
    salaam :'Salaam',
    title :'sync.2us.ma',
    app :'Synchronize',
    logo :'logo.png'
}

const header = {
    joinus : "Join us",
    signin : "Login",    
    signout : "Exit"
}

const home = {
    title:'Synchronize',
    about:'Synchronize Service from Local to Cloud'
}

const blog = {
    title :'Most Interesting Articles',
    read:'Read more...'
}

const path = {
    icons : 'https://2us.ma/content/icons/'
}

const icons = {
    add : path.icons+'add.png',
    reload : path.icons+'reload.png',
    minimize : path.icons+'minimize.png',
    maximize : path.icons+'maximize.png'
}

const plan = {
    title :'Subscription & Plans',
    order:'Order Now',
    icon:'sync-1.png',
    link:'/order'
}

const contact = {
    title:'Get In Touch',
    message:'Please fill out the form below to send us an email',
    confirm:{
        title:'Email Confirming',
        content:'Please fill out the code we sent you by email to confirm it'
    },
    feedback : 'We have received your message, we will get back to you as soon as possible'
}

export default Config
export {header, home, blog, icons, plan, contact}